import * as React from 'react'
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from "gatsby";


export const query = graphql`
    query {
        file(sourceInstanceName: {eq: "assets"}, name: {eq: "Dmitry Lukashin"}) {
            publicURL
        }
    }
`
const AboutPage = ({ data }) => {
    return (
        <Layout pageTitle="About page">
            <p>Hey there! This is actually just an About page</p>
            <a href={data.file.publicURL} target="_blank" rel="noreferrer noopener">Download CV</a>
        </Layout>
    )
}

export const Head = () => <Seo title="About" />
export default AboutPage;